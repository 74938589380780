import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { EquipamentoCadastro } from '../../../model/acoes/equipamento/manutencao-equipamento-cadastro';
import { Equipamento } from '../../../model/acoes/equipamento/manutencao-equipamento';
import { ManutencaoItemEquipamento } from '../../../model/acoes/equipamento/manutencao-item-equipamento';
import { ManutencaoTPM } from '../../../model/acoes/equipamento/manutencao-tpm';
import { ManutencaoChecklist } from '../../../model/acoes/equipamento/manutencao-checklist';
import { ManutencaoComponenteCritico } from '../../../model/acoes/equipamento/manutencao-componente-critico';
import { ManutencaoOsCadastroDTO } from '../../../model/acoes/ordem-servico/ordem-servico-cadastro';

@Injectable({
  providedIn: 'root'
})
export class EquipamentoService {

  urlApi: string = environment.url + '/manutencao-equipamento'

  constructor(
    private http: HttpClient
  ) { }

  public consultaTodosEquipamentos(): Observable<any> {
    return this.http.get<any>(this.urlApi);
  }

  public consultaPaginadaDeEquipamento(page: number, size: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/${page}/${size}`);
  }

  public cadastrarEquipamento(equipamento: EquipamentoCadastro): Observable<any> {
    return this.http.post<any>(`${this.urlApi}`, equipamento);
  }

  public alterarEquipamento(equipamento: EquipamentoCadastro): Observable<any> {
    return this.http.put<any>(`${this.urlApi}`, equipamento);
  }

  public consultarEquipamentoPorId(idEquipamento: number): Observable<Equipamento> {
    return this.http.get<Equipamento>(`${this.urlApi}/${idEquipamento}`);
  }

  public incluirOuAlterarItemEquipamento(idEquipamento: number, itemEquipamento: ManutencaoItemEquipamento): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/item-equipamento/${idEquipamento}`, itemEquipamento);
  }

  public incluirOuAlterarTpmEquipamento(idEquipamento: number, tpm: ManutencaoTPM): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/tpm-equipamento/${idEquipamento}`, tpm);
  }

  public incluirOuAlterarChecklist(idEquipamento: number, checklist: ManutencaoChecklist): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/checklist-equipamento/${idEquipamento}`, checklist);
  }

  public incluirOuAlterarComponenteCritico(idEquipamento: number, componente: ManutencaoComponenteCritico): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/componente-critico-equipamento/${idEquipamento}`, componente);
  }

  public consultaPreventivasDoMes(inicio: string, fim: string, dataReferencia: string): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/preventivas/${inicio}/${fim}/${dataReferencia}`);
  }

  /**
   * Utilizado para cadastrar a manutenção preventica dos equipamentos que precisam ser gerenciados
   * pelo sistema
   */
  public cadastrarOsEquipamento(os: ManutencaoOsCadastroDTO): Observable<any> {
    return this.http.post(`${this.urlApi}/preventiva-equipamento`, os);
  }

}
